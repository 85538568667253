import { caseDetailPopup } from '@codametrix/shared-views';
import { openWindow } from '../../actions/ui';
import store from '../../store/store';

export const displayJsonInNewWindow = (data: any) => {
  sessionStorage.setItem('json_view_data', JSON.stringify(data));
  (store.dispatch as any)(
    openWindow({ ...caseDetailPopup, params: { hasInlineJson: true } })
  );
};
