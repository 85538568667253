import { connect } from 'react-redux';
import {
  worklistMapDispatchToProps,
  worklistMapStateToProps
} from './service-desk-component';
import {
  mapUpdatedDispatchToCodeBenchProps,
  mapUpdatedStateToCodeBenchProps
} from '../../views/accelerate/code-bench-component';
import { ServiceDeskAndGradeBench } from './service-desk-and-gradebench-component';
import {
  codeBenchActions,
  gradeBenchPanelActions
} from '@codametrix/ui-common';
import {
  launchWorkitemInAmplify,
  getPrevOrNextCase
} from '../../actions/cases.action';
import { isCQAWorkItemEvaluated } from '../../core/cqa/CQAWorkItemEvaluated';

const stateMapper = (state: CMx.ApplicationState) => {
  const enabled = { value: true, reasons: [] };

  const onCapability: CMxCommonApp.Capability = {
    on: enabled,
    actionable: enabled
  };

  return {
    listProps: worklistMapStateToProps(state),
    detailProps: {
      ...mapUpdatedStateToCodeBenchProps(state),
      gradeableInvoices: state.gradeBenchPanel.invoices,
      fieldDefinitions: state.gradeBenchPanel.fieldDefinitions,
      qaRuleCollection: {
        sampleReason: state.gradeBenchPanel.qaRule.data.sample_reason,
        guideline: state.gradeBenchPanel.qaRule.data.guideline,
        project_configuration:
          state.gradeBenchPanel.qaRule.data.project_configuration
      },
      capabilities: {
        ...state.capabilities,
        capabilities: {
          ...state.capabilities.capabilities,
          servicedeskAction: onCapability,
          submitCase: onCapability
        }
      },
      userContext: state.ui.context.activeContext,
      riskCategories: state.gradeBenchPanel.riskCategories,
      cqaCodeAssessmentRiskDictionaries:
        state.gradeBenchPanel.cqaCodeAssessmentRiskDictionaries,
      gradingErrors: state.gradeBenchPanel.gradingErrors,
      actionBarSumbmitEnabled: isCQAWorkItemEvaluated(state.gradeBenchPanel)
    }
  };
};
const dualDispatchMapper = (dispatch: any) => {
  return {
    listMethods: {
      ...worklistMapDispatchToProps(dispatch),
      openAmplifyCase: (launchConfig: CMxCommonApp.LaunchWorkItemConfig) => {
        dispatch(launchWorkitemInAmplify(launchConfig));
        dispatch(codeBenchActions.hideSideNavBar());
      }
    },
    detailMethods: {
      ...mapUpdatedDispatchToCodeBenchProps(dispatch),
      onGradeUpdate: (update: CMxCommonApp.GradeBenchSelectEvent) => {
        dispatch(gradeBenchPanelActions.gradeUpdate(update));
      },
      fetchGradePanelConfigurations: () => {
        dispatch(gradeBenchPanelActions.fetchRiskCategories());
      },
      onCodeSearchSelection: (update: Accelerate.CodeInfo) => {
        dispatch(gradeBenchPanelActions.addCode(update));
      },
      onGradeRemove: (update: Accelerate.DeleteCQACodeParams) => {
        dispatch(gradeBenchPanelActions.deleteCQACode(update));
      },
      onCodeEvent: (update: CMxCommonApp.GradeBenchCodeEvent) => {
        dispatch(gradeBenchPanelActions.resequenceCode(update));
      },
      fetchQaRule: (update: Accelerate.FetchQaRuleParams) => {
        dispatch(gradeBenchPanelActions.fetchQaRule(update));
      },
      onCursorAction: async (
        cursorAPIPayload: CMxCommonApp.CursorAPIPayload
      ) => {
        dispatch(getPrevOrNextCase(cursorAPIPayload));
      },
      onLoadFromURLMatch: (params: any) => {
        dispatch(gradeBenchPanelActions.loadCQAItemFromURL(params));
      },
      onCreateCQATransaction: (
        update: Accelerate.CreateCQATransactionParams
      ) => {
        dispatch(gradeBenchPanelActions.createCQATransaction(update));
      },
      onDeleteCQATransaction: (
        update: Accelerate.DeleteCQATransactionParams
      ) => {
        dispatch(gradeBenchPanelActions.deleteCQATransaction(update));
      },
      submitCQAWorkitem: (cursorAPIPayload: CMxCommonApp.CursorAPIPayload) => {
        dispatch(gradeBenchPanelActions.submitCQAWorkitem(cursorAPIPayload));
      }
    }
  };
};

export const ConnectedServiceDeskAndGradeBench = connect(
  stateMapper,
  dualDispatchMapper
)(ServiceDeskAndGradeBench);
