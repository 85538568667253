/// <reference path="../types.d.ts" />

import actionCreatorFactory from 'typescript-fsa';
import { Analytics } from '../actions/action-types';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import { HttpMethods, appServiceLines } from '@codametrix/ui-common';

/** factories for actions */
const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.AnalyticsState>(actionCreator);
const instantiateDashboard = actionCreator<void>(
  Analytics.INSTANTIATE_DASHBOARD
);

const setActiveDashboard = actionCreator<string>(
  Analytics.SET_ACTIVE_DASHBOARD
);

const setActiveDashboardResource = actionCreator<string>(
  Analytics.SET_ACTIVE_DASHBOARD_RESOURCE
);

type TableauToken = {
  tableauToken: string;
};

const fetchTableauToken = createAsync<
  CMx.ServiceLine | undefined,
  String,
  void
>(Analytics.GET_TABLEAU_TOKEN, async (serviceLine, dispatch) => {
  const searchParams = new URLSearchParams({});

  if (serviceLine) {
    searchParams.set('serviceLine', serviceLine.name);
  }

  const result = await api<TableauToken>({
    endpoint: `/tableau/token/v1?${searchParams.toString()}`,
    init: {
      method: HttpMethods.POST
    }
  });

  return result?.tableauToken ?? '';
});

type UserDetails = {
  tenantId: string;
  serviceLine: string;
};

const getSubNavPaths = createAsync<
  UserDetails,
  CMxAPI.ConfigurationSection[],
  void
>(Analytics.GET_SUB_NAV_PATHS, async (userDetails, dispatch) => {
  const searchParams = new URLSearchParams({});

  if (userDetails?.tenantId) {
    searchParams.set('tenantId', userDetails.tenantId);
  }

  let serviceLine = '*';
  if (userDetails?.serviceLine) {
    serviceLine = appServiceLines.isIPC(userDetails.serviceLine)
      ? 'Inpatient Professional Coding'
      : userDetails.serviceLine;
  }

  searchParams.set('serviceLine', serviceLine);

  const result = await api<CMxAPI.ConfigurationSection[]>({
    endpoint: `/configuration/section/subnav/v2?${searchParams.toString()}`,
    init: {
      method: HttpMethods.GET
    }
  });

  return result;
});

export {
  instantiateDashboard,
  fetchTableauToken,
  setActiveDashboard,
  setActiveDashboardResource,
  getSubNavPaths
};
