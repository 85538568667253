/**
 * Determines if all CQA work items in the given grade bench panel state are evaluated. Uses the project configuration to determine which codes and assessment fields are required.
 *
 * @param {Accelerate.GradeBenchPanelState} gradeBenchPanelState - The state of the grade bench panel containing QA rules and invoices.
 * @returns {boolean} - Returns true if all work items are evaluated, otherwise false.
 */
export const isCQAWorkItemEvaluated = (
  gradeBenchPanelState: Accelerate.GradeBenchPanelState
) => {
  const projectConfiguration =
    gradeBenchPanelState.qaRule.data.project_configuration;
  const invoices = gradeBenchPanelState.invoices;

  /**
   * Checks if a given code is evaluated based on the project configuration.
   *
   * @param {Accelerate.CQACode} code - The code to evaluate.
   * @returns {boolean} - Returns true if the code is evaluated, otherwise false.
   */
  const isCodeEvaluated = (code: Accelerate.CQACode) => {
    if (projectConfiguration.assessment && !code.codeAssessment?.assessment) {
      return false;
    }
    if (projectConfiguration.role && !code.codeAssessment?.role) {
      return false;
    }
    return true;
  };

  /**
   * Checks if all codes in the given array are evaluated.
   *
   * @param {Accelerate.CQACode[]} codes - The array of codes to evaluate.
   * @returns {boolean} - Returns true if all codes are evaluated, otherwise false.
   */
  const checkIfCodesEvaluated = (codes: Accelerate.CQACode[]) => {
    return codes.every(c => isCodeEvaluated(c));
  };

  return invoices.every(invoice =>
    invoice.transactions.every(transaction => {
      if (
        projectConfiguration['procedure'] &&
        !checkIfCodesEvaluated(transaction.procedures)
      ) {
        return false;
      }
      if (
        projectConfiguration['diagnosis'] &&
        !checkIfCodesEvaluated(transaction.diagnoses)
      ) {
        return false;
      }
      if (
        projectConfiguration['modifier'] &&
        !checkIfCodesEvaluated(transaction.modifiers)
      ) {
        return false;
      }
      return true;
    })
  );
};
